<template>
  <div class="columns">
    <div class="column" :class="!isFullWidth && 'is-two-thirds'">
      <b-collapse :open="configurationFormOpen" class="box" animation="slide">
        <div slot="trigger" class="is-flex configuration-item">
          <h2 class="title is-5 mb-0">
            Script Configuration (Change Reloads Script)
          </h2>
          <b-button size="is-small">Toggle</b-button>
        </div>

        <div class="configuration-item--content">
          <ConfigurationForm
            :config-key="configKey"
            :preconfigured-config-keys="preconfiguredConfigKeys"
            :bootstrapped="isBootstrapped"
            :integrator-name="integratorName"
            @setConfigKey="setConfigKey"
            @setIntegratorName="setIntegratorName"
            @setIntegration="setIntegration"
            @setMode="setMode"
            @setHeaderHidden="setHeaderHidden"
            @setLauncherHidden="setLauncherHidden"
            @setCloseDisabled="setCloseDisabled"
            @setCookieless="setCookieless"
            @setMessageDelayDisabled="setMessageDelayDisabled"
            @setExternalVariablesObjectName="setExternalVariablesObjectName"
            @configure="update"
            @bootstrap="bootstrap"
            @teardown="teardown"
            @openStandalone="openStandalone"
          />
        </div>
      </b-collapse>

      <b-collapse :open="chatFormOpen" class="box" animation="slide">
        <div slot="trigger" class="is-flex configuration-item">
          <h2 class="title is-5 mb-0">Chat</h2>
          <b-button size="is-small">Toggle</b-button>
        </div>

        <div class="configuration-item--content">
          <ChatForm
            @open="open"
            @close="close"
            @positionRight="positionRight"
            @positionLeft="positionLeft"
            @show="show"
            @hide="hide"
          />
        </div>
      </b-collapse>

      <b-collapse :open="variablesFormOpen" class="box" animation="slide">
        <div slot="trigger" class="is-flex configuration-item">
          <h2 class="title is-5 mb-0">External Variables</h2>
          <b-button size="is-small">Toggle</b-button>
        </div>

        <div class="configuration-item--content">
          <VariablesForm v-model="variables" @update="updateVariables" />
        </div>
      </b-collapse>

      <b-collapse :open="configVariablesFormOpen" class="box" animation="slide">
        <div slot="trigger" class="is-flex configuration-item">
          <h2 class="title is-5 mb-0">Config Variables</h2>
          <b-button size="is-small">Toggle</b-button>
        </div>

        <div class="configuration-item--content">
          <ConfigVariablesForm
            v-model="configVariables"
            @update="updateConfigVariables"
          />
        </div>
      </b-collapse>

      <b-collapse :open="eventFormOpen" class="box" animation="slide">
        <div slot="trigger" class="is-flex configuration-item">
          <h2 class="title is-5 mb-0">Events</h2>
          <b-button size="is-small">Toggle</b-button>
        </div>

        <div class="configuration-item--content">
          <EventForm @trigger="triggerEvent" />
        </div>
      </b-collapse>

      <b-collapse :open="messageFormOpen" class="box" animation="slide">
        <div slot="trigger" class="is-flex configuration-item">
          <h2 class="title is-5 mb-0">Messages</h2>
          <b-button size="is-small">Toggle</b-button>
        </div>

        <div class="configuration-item--content">
          <MessageForm @message="sendMessage" @clear="clear" />
        </div>
      </b-collapse>

      <b-collapse :open="themeFormOpen" class="box" animation="slide">
        <div slot="trigger" class="is-flex configuration-item">
          <h2 class="title is-5 mb-0">Theme</h2>
          <b-button size="is-small">Toggle</b-button>
        </div>

        <div class="configuration-item--content">
          <ThemeForm v-model="theme" @update="updateTheme" />
        </div>
      </b-collapse>

      <b-collapse :open="behaviourFormOpen" class="box" animation="slide">
        <div slot="trigger" class="is-flex configuration-item">
          <h2 class="title is-5 mb-0">Behaviour</h2>
          <b-button size="is-small">Toggle</b-button>
        </div>

        <div class="configuration-item--content">
          <BehaviourForm v-model="behaviour" @update="updateBehaviour" />
        </div>
      </b-collapse>

      <b-collapse :open="conversationsFormOpen" class="box" animation="slide">
        <div slot="trigger" class="is-flex configuration-item">
          <h2 class="title is-5 mb-0">Conversations</h2>
          <b-button size="is-small">Toggle</b-button>
        </div>

        <div class="configuration-item--content">
          <ConversationsForm />
        </div>
      </b-collapse>

      <b-collapse :open="debugFormOpen" class="box" animation="slide">
        <div slot="trigger" class="is-flex configuration-item">
          <h2 class="title is-5 mb-0">Debug</h2>
          <b-button size="is-small">Toggle</b-button>
        </div>

        <div class="configuration-item--content">
          <DebugForm @setFrameAreaVisible="setFrameAreaVisible" />
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { defaultTheme } from '@/chatbot/models/Theme'
import { defaultBehaviour } from '@/chatbot/models/Behaviour'

import ThemeForm from '@/dashboard/components/forms/ThemeForm'
import ConfigurationForm from '@/dashboard/components/forms/ConfigurationForm'
import ChatForm from '@/dashboard/components/forms/ChatForm'
import DebugForm from '@/dashboard/components/forms/DebugForm'
import MessageForm from '@/dashboard/components/forms/MessageForm'
import EventForm from '@/dashboard/components/forms/EventForm'
import BehaviourForm from '@/dashboard/components/forms/BehaviourForm'
import VariablesForm from '@/dashboard/components/forms/VariablesForm'
import ConfigVariablesForm from '@/dashboard/components/forms/ConfigVariablesForm'
import ConversationsForm from '@/dashboard/components/forms/ConversationsForm'
import {
  MODE_ATTRIBUTE_NAME,
  API_KEY_ATTRIBUTE_NAME,
  COOKIELESS_ATTRIBUTE_NAME,
  INTEGRATION_ATTRIBUTE_NAME,
  INTEGRATION_ATTRIBUTE_VERSION_NAME,
  HIDDEN_HEADER_ATTRIBUTE_NAME,
  HIDDEN_LAUNCHER_ATTRIBUTE_NAME,
  CLOSE_DISABLED_ATTRIBUTE_NAME,
  MESSAGE_DELAY_DISABLED_ATTRIBUTE_NAME,
  VARIABLES_OBJECT_ATTRIBUTE_NAME,
  DEFAULT_VARIABLES_OBJECT_NAME,
  DEFAULT_CONFIG_VARIABLES_OBJECT_NAME,
  INTEGRATOR_CONFIG_NAME_ATTRIBUTE_NAME,
  CHAT_CONSENT_DECLINED,
} from '@/common/common-constants'

/**
 * Generate some javascript code in the DOM like it would have been rendered server side
 *
 * window.aiaibotVariables = {
 *   "variable1": "value1",
 *   "variable2": "value2",
 * }
 */
function createVariableScript(variables, id, externalVariablesObjectName) {
  // If there is already a script with variables, remove it
  const existingVariablesScript = document.querySelector(`script[id="${id}"]`)

  if (existingVariablesScript) {
    existingVariablesScript.remove()
  }

  // Create a new script node that contains our variables
  const variablesScript = window.document.createElement('script')

  variablesScript.setAttribute('type', 'text/javascript')
  variablesScript.setAttribute('id', id)

  const variablesDefinition = Object.keys(variables).reduce(
    (definitions, variable) => {
      const value = variables[variable]

      // "key": "value",
      const definition = `\t${JSON.stringify(variable)}: ${JSON.stringify(
        value,
      )},\n`

      return definitions + definition
    },
    '',
  )

  variablesScript.innerHTML = `window.${externalVariablesObjectName} = {\n${variablesDefinition}};`

  return variablesScript
}

export default {
  components: {
    ConfigurationForm,
    ThemeForm,
    ChatForm,
    DebugForm,
    MessageForm,
    EventForm,
    BehaviourForm,
    VariablesForm,
    ConfigVariablesForm,
    ConversationsForm,
  },
  props: {
    isFullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      theme: defaultTheme,
      behaviour: defaultBehaviour,
      isBootstrapped: true,
      configKey: process.env.DEFAULT_CLIENT_CONFIG_KEY,
      preconfiguredConfigKeys: process.env.MOCK_CLIENT_CONFIG_KEYS.split(','),
      integratorName: '',
      mode: 'live',
      externalVariablesObjectName: DEFAULT_VARIABLES_OBJECT_NAME,
      variables: {
        current_time: new Date().toISOString(),
        hostname: window.location.host,
        name: 'Jon Doe',
      },
      configVariables: {
        jwt: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',
      },
      configurationFormOpen: true,
      chatFormOpen: true,
      eventFormOpen: false,
      messageFormOpen: false,
      themeFormOpen: false,
      behaviourFormOpen: false,
      debugFormOpen: false,
      variablesFormOpen: true,
      configVariablesFormOpen: false,
      conversationsFormOpen: false,
    }
  },
  computed: {
    bootstrapScript() {
      return document.querySelector(`script[id="bootstrap"]`)
    },
  },
  watch: {
    configKey(newConfigKey) {
      const query = this.$route.query

      this.$router
        .push({
          path: this.$route.path,
          query: { ...query, key: newConfigKey },
        })
        .catch(() => {})
    },
    integratorName(newIntegratorName) {
      const query = this.$route.query

      this.$router.push({
        path: this.$route.path,
        query: { ...query, integrator: newIntegratorName },
      })
    },
  },
  async mounted() {
    // Fetch configKey from route, if present, overriding default
    if (this.$route.query.key) {
      this.configKey = this.$route.query.key
      await this.setConfigKey(this.configKey)
    }

    if (this.$route.query.integrator) {
      this.integratorName = this.$route.query.integrator
      await this.setIntegratorName(this.integratorName)
    }
  },
  methods: {
    async bootstrap() {
      if (window.aiaibot) {
        window.localStorage.removeItem(CHAT_CONSENT_DECLINED)
        await window.aiaibot.bootstrap()
        this.isBootstrapped = true
      }
    },
    teardown() {
      if (window.aiaibot) {
        window.aiaibot.teardown()
        this.isBootstrapped = false
      }
    },
    openStandalone() {
      const url = `/embed.html/#/?key=${window.aiaibot.config.configKey}&trigger=${window.aiaibot.config.activeTriggerId}&uid=${window.aiaibot.context.user.uid}`

      window.open(url, '_blank')
    },
    update() {
      window.aiaibot.update(window.aiaibot.config, window.aiaibot.context)
    },
    updateTheme(theme) {
      window.aiaibot.config.theme = { ...window.aiaibot.config.theme, ...theme }
      this.update()
    },
    updateBehaviour(behaviour) {
      window.aiaibot.config.behaviour = {
        ...window.aiaibot.config.behaviour,
        ...behaviour,
      }
      this.update()
    },
    async updateVariables({ variables, useApi = false }) {
      if (useApi) {
        window.aiaibot.setVariables(variables)

        return
      }

      const script = createVariableScript(
        variables,
        'variables',
        this.externalVariablesObjectName,
      )

      // Insert the node BEFORE the bootstrap script
      this.bootstrapScript.insertAdjacentElement('beforebegin', script)

      // As the DOM has changed to contain the variables, we have to run bootstrap again
      this.teardown()
      await this.bootstrap()
    },
    async updateConfigVariables({ variables, useApi = false }) {
      if (useApi) {
        window.aiaibot.setConfigVariables(variables)

        return
      }

      const script = createVariableScript(
        variables,
        'config-variables',
        DEFAULT_CONFIG_VARIABLES_OBJECT_NAME,
      )

      // Insert the node BEFORE the bootstrap script
      this.bootstrapScript.insertAdjacentElement('beforebegin', script)

      // As the DOM has changed to contain the variables, we have to run bootstrap again
      this.teardown()
      await this.bootstrap()
    },
    loadEmbed() {
      window.aiaibot.loadUrl(
        '/embed.html',
        window.aiaibot.config.configKey,
        window.aiaibot.config.activeTrigger,
      )
    },
    loadAboutBlank() {
      window.aiaibot.loadUrl('about:blank')
    },
    show() {
      window.aiaibot.show()
    },
    hide() {
      window.aiaibot.hide()
    },
    open() {
      window.aiaibot.open()
    },
    close() {
      window.aiaibot.close()
    },
    positionRight() {
      window.aiaibot.positionRight()
    },
    positionLeft() {
      window.aiaibot.positionLeft()
    },
    clear() {
      window.aiaibot.clear()
    },
    async setConfigKey(key) {
      this.configKey = key
      this.bootstrapScript.setAttribute(API_KEY_ATTRIBUTE_NAME, key)

      this.teardown()
      await this.bootstrap()
    },
    async setIntegratorName(integratorName) {
      this.integratorName = integratorName

      if (integratorName) {
        this.bootstrapScript.setAttribute(
          INTEGRATOR_CONFIG_NAME_ATTRIBUTE_NAME,
          integratorName,
        )
      } else {
        this.bootstrapScript.removeAttribute(
          INTEGRATOR_CONFIG_NAME_ATTRIBUTE_NAME,
        )
      }

      this.teardown()
      await this.bootstrap()
    },
    async setIntegration(integration) {
      this.bootstrapScript.setAttribute(INTEGRATION_ATTRIBUTE_NAME, integration)
      this.bootstrapScript.setAttribute(
        INTEGRATION_ATTRIBUTE_VERSION_NAME,
        process.env.VERSION,
      )

      this.teardown()
      await this.bootstrap()
    },
    async setMode(mode) {
      this.bootstrapScript.setAttribute(MODE_ATTRIBUTE_NAME, mode)

      this.teardown()
      await this.bootstrap()
    },
    async setHeaderHidden(hidden) {
      if (hidden) {
        this.bootstrapScript.setAttribute(HIDDEN_HEADER_ATTRIBUTE_NAME, 'true')
      } else {
        this.bootstrapScript.removeAttribute(HIDDEN_HEADER_ATTRIBUTE_NAME)
      }

      this.teardown()
      await this.bootstrap()
    },
    async setLauncherHidden(hidden) {
      if (hidden) {
        this.bootstrapScript.setAttribute(
          HIDDEN_LAUNCHER_ATTRIBUTE_NAME,
          'true',
        )
      } else {
        this.bootstrapScript.removeAttribute(HIDDEN_LAUNCHER_ATTRIBUTE_NAME)
      }

      this.teardown()
      await this.bootstrap()
    },
    async setCloseDisabled(disabled) {
      if (disabled) {
        this.bootstrapScript.setAttribute(CLOSE_DISABLED_ATTRIBUTE_NAME, 'true')
      } else {
        this.bootstrapScript.removeAttribute(CLOSE_DISABLED_ATTRIBUTE_NAME)
      }

      this.teardown()
      await this.bootstrap()
    },
    async setCookieless(disabled) {
      if (disabled) {
        this.bootstrapScript.setAttribute(COOKIELESS_ATTRIBUTE_NAME, 'true')
      } else {
        this.bootstrapScript.removeAttribute(COOKIELESS_ATTRIBUTE_NAME)
      }

      this.teardown()
      await this.bootstrap()
    },
    async setMessageDelayDisabled(disabled) {
      if (disabled) {
        this.bootstrapScript.setAttribute(
          MESSAGE_DELAY_DISABLED_ATTRIBUTE_NAME,
          'true',
        )
      } else {
        this.bootstrapScript.removeAttribute(
          MESSAGE_DELAY_DISABLED_ATTRIBUTE_NAME,
        )
      }

      this.teardown()
      await this.bootstrap()
    },
    async setExternalVariablesObjectName(externalVariablesObjectName) {
      this.bootstrapScript.setAttribute(
        VARIABLES_OBJECT_ATTRIBUTE_NAME,
        externalVariablesObjectName,
      )

      this.externalVariablesObjectName = externalVariablesObjectName

      this.teardown()
      await this.bootstrap()
    },
    setFrameAreaVisible(visible = true) {
      window.aiaibot.frame.style.background = visible
        ? 'rgba(255, 0, 0, 0.25)'
        : 'rgba(0, 0, 0, 0)'
    },
    sendMessage(payload) {
      window.aiaibot.sendMessage(payload)
    },
    triggerEvent(name) {
      const event = new Event(name)

      document.dispatchEvent(event)
    },
  },
}
</script>

<style lang="scss" scoped>
.mb-0 {
  margin-bottom: 0 !important;
}

.configuration-item {
  align-items: center;
  justify-content: space-between;
}

.configuration-item--content {
  margin-top: $baseline;
}
</style>
